
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import moment from "moment";
export default defineComponent({
  mixins: [apiEndpoint],
  name: "super-admin-mutiple-enrollment",
  components: {
    Field,
    Datatable,
  },
  data() {
    return {
      api_url: "",
      trainee: {
        entity_id: "",
        training_institute_id: "",
        tranche_id: "",
        course_info_id: "",
        registration_number: "",
        reference_number: "",
        nid: "",
        bcn: "",
      },
      
      institutekey: 0,
      batchkey: 0,
      allowMultiple: false,
      associations: [],
      institutes: [],
      tranches: [],
      courses: [],
      moment: "" as any,
      traineeInfo: [],
      load: false,
      showTraineeInfo: false,
      loading: false,
      btnCheck: false
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async associationList() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.associations = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("institute/list?entity_id=" + entity_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getCourseList() {
      this.load = true;
      await ApiService.get(
        "course/list?entity_id=" +
          this.trainee.entity_id +
          "&tranche=" +
          this.trainee.tranche_id +
          "&institute_info_id=" +
          this.trainee.training_institute_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

   async getTraineeInfo() {
      this.load = true;
      await ApiService.get(
        "trainee/search?registration_number=" +
          this.trainee.registration_number +
          "&reference_number=" +
          this.trainee.reference_number +
          "&nid=" +
          this.trainee.nid +
          "&bcn=" +
          this.trainee.bcn
      )
        .then((response) => {
          this.load = false;
          this.traineeInfo = response.data.data;
          this.showTraineeInfo = true;``
          this.allowMultiple = false;
          if (response.data.data.allow_dup_enrollment == 1){
            this.allowMultiple = true;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    allowEnroll(id) {
        if(this.trainee.tranche_id && this.trainee.training_institute_id && this.trainee.entity_id && this.trainee.course_info_id.length !== 0 && this.allowMultiple) {
     let data = {
        trainee_info_id: id,
        course_info_id: this.trainee.course_info_id,
      };
  
        Swal.fire({
          title: "Are you sure you want to allow duplicate/multiple enrollment for this trainee?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Allow!",
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post("configurations/super_admin/multiple_enroll", data)
              .then((response) => {
                Swal.fire("Success!", response.data.data, "success").then(() => {
                  this.showTraineeInfo = false;
                });
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      }else{
         Swal.fire({
          title: "Please check all the required field",
          html: "",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },
    
  },
});
